import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import TabMenu from "../../../TabMenu";
import { onCreateNewBuild, onGetAppBuilds, onGetAppSettings, onCreateAppSettings, onUploadMedia } from "../../../../../../actions/commonAction";
import Loading from "../../../../common/Loading";
import { useDispatch } from "react-redux";
import Save from "./appConfig/Save";
import apiConfig from "../../../../../../apiConfig";
import { getBase64 } from "../../../../../../helpers/util";
import PopupConfirm from "../../../../common/PopupConfirm";

const Menus = [{
    key: 'appconfiguration',
    value: 'App Configuration'
}, {
    key: 'builds',
    value: 'Builds'
}]

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
`;
const BuildNow = styled.div`
    height: 32px;
    background: #121212;
    border-radius: 4px;
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 18px;
    cursor: pointer;
`;

const Android = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [buildsApps, setBuildsApps] = useState(null);
    const [appConfig, setAppConfig] = useState(null);
    const [editAppConfig, setEditAppConfig] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const onSelectedTabMenu = (item) => {
        if (item && item.key !== selectedMenu) {
            setSelectedMenu(item.key)
        }
    }
    const getBuildApps = async () => {
        setLoading(true);
        const resp: any = await onGetAppBuilds('android')(dispatch);
        if (resp && resp.appBuilds) {
            setBuildsApps(resp.appBuilds);
        }
        setLoading(false);
    }
    const getAppConfig = async () => {
        setLoading(true);
        const resp: any = await onGetAppSettings('android')(dispatch);
        if (resp && resp.appSettings) {
            setAppConfig(resp.appSettings);
        }
        setLoading(false);
    }
    const onNewBuild = async () => {
        setLoading(true);
        const resp = await onCreateNewBuild('android')(dispatch);
        if (resp) {
            getBuildApps();
        }
        setLoading(false);
    }
    const onNewAppSettings = async () => {
        setEditAppConfig(appConfig);
        setIsEdit(true)
    }
    const onSave = async (data) => {
        let isAPIError = false;
        setLoading(true);
        let googleServicesConfig = null;
        if (data.googleServicesConfig && data.googleServicesConfig.originFileObj) {
            // await onUploadAppSettings({
            //     settingName: "android-google-services",
            //     file: data.googleServicesConfig.originFileObj
            // })(dispatch);
            googleServicesConfig = await getBase64(data.googleServicesConfig.originFileObj);
            googleServicesConfig = googleServicesConfig.split('data:application/json;base64,')[1];
        }
        const {
            appName,
            appIcon,
            splashColor,
            bundleId
        } = data;
        let payload = {
            appName,
            appIcon,
            splashColor,
            bundleId
        };
        if (googleServicesConfig) {
            payload['googleServicesConfig'] = googleServicesConfig;
        }
        if (appIcon && appIcon.originFileObj) {
            let bodyFormData = new FormData();
            bodyFormData.append('images', appIcon.originFileObj);
            bodyFormData.append('forUse', 'appGenerationIcon');
            setLoading(true);
            const uploadResp: any = await onUploadMedia(bodyFormData)(dispatch);
            if (uploadResp && uploadResp.isUploaded) {
                payload.appIcon = `${apiConfig.baseImageUrl}${uploadResp.images[0]}`;
            } else {
                isAPIError = true;
            }
        } else if (appIcon.url) {
            payload.appIcon = appIcon.url;
        }
        const resp = await onCreateAppSettings(payload, "android")(dispatch);
        if (!resp || !resp.appSettings) {
            isAPIError = true;
        }
        setLoading(false);
        if (!isAPIError) {
            setIsEdit(false);
            getAppConfig();
        }
    }
    const onBuildConfirm = () => {
        const title = `Create a New App Build`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Want to make a new version  of the <span style={{ fontWeight: "bold" }}>{appConfig.appName}</span> App?</span></div > },
            yes: {
                label: "Build Now",
                callback: async () => {
                    setPopupConfirm(null);
                    onNewBuild();
                }
            },
            no: {
                label: "May be next time",
                callback: () => {
                    setPopupConfirm(null);
                }
            }
        })
    }
    useEffect(() => {
        if (selectedMenu === "builds") {
            getBuildApps();
            getAppConfig();
        } else if (selectedMenu === "appconfiguration") {
            getAppConfig();
        }
        //eslint-disable-next-line
    }, [selectedMenu]);
    return <Container>
        {isLoading && <Loading background="#00000070"></Loading>}
        <div className="settings" style={{ flexDirection: 'column' }}>
            <div style={{
                display: 'flex',
                background: '#FFFFFF',
                border: '1px solid #D9D9D9',
                borderRadius: 8,
                width: 'auto',
                overflow: 'hidden',
                flexDirection: 'column',
                margin: "15px 15px 0px",
                boxShadow: "0px 4px 16px 0px rgba(198, 198, 198, 0.12)"
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'auto',
                    padding: '0px 16px',
                    flexGrow: 1,
                    borderBottom: "1px solid #D9D9D9"
                }}>
                    <TabMenu style={{ width: "auto" }} defaultMenu={null} onSelectedTabMenu={onSelectedTabMenu} menus={Menus}></TabMenu>
                    {selectedMenu === "builds" && <BuildNow onClick={onBuildConfirm}>Build New Version</BuildNow>}
                    {selectedMenu === "appconfiguration" && appConfig && <BuildNow onClick={() => { setEditAppConfig(appConfig); setIsEdit(true) }}>Edit</BuildNow>}
                </div>
                <Outlet context={{
                    onBuildConfirm,
                    buildsApps,
                    appConfig,
                    onNewAppSettings
                }} />
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {<Save onSave={onSave} isVisible={isEdit} appConfig={editAppConfig} onClose={() => { setIsEdit(false); setEditAppConfig(null); }} />}
    </Container >
}

export default Android;