import { Drawer, DatePicker, Select, Modal, Tabs, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import Loading from '../common/Loading';
import '../../../assets/sass/dsk/notification.sass';
import InputValidation from '../common/InputValidation';
import PlusSvg from "../../../assets/images/gallery-add.svg";
import UploadIcn from "../../../assets/images/category_upload.svg";
import ScheduleNotifySvg from "../../../assets/images/settings/schedule-notify.svg";
import DeliveryNotifySvg from "../../../assets/images/settings/delivery-notify.svg";
import SelectedCricleSvg from "../../../assets/images/settings/selected-circle.svg";
import SearchIcn from "../../../assets/images/search.svg";
import { EditOutlined, ArrowLeftOutlined, DeleteOutlined, CloseCircleOutlined, CaretDownOutlined } from "@ant-design/icons";
import { onCreateNotification, onUpdateNotification } from '../../../actions/notificationActions';
import { getBase64, isNotNullOrUndefinedString } from '../../../helpers/util';
import { useDispatch } from 'react-redux';
import { getMediaFiles, onUploadMedia } from '../../../actions/commonAction';
import config from "../../../apiConfig";
import Dragger from 'antd/lib/upload/Dragger';
import { getCategories } from '../../../actions/categoryActions';
import { getProducts } from '../../../actions/productActions';
import { getL10N } from '../../../helpers/middleware';
import dayjs from 'dayjs';

const { Option } = Select;
const { TabPane } = Tabs;
const Audience = [{
    key: "everyone",
    label: "Everyone"
}];

const CallToActions = [
    { key: "Open_Category", label: "Open Category" },
    { key: "Open_Product_Detail", label: "Open Product Detail" },
    { key: "Open_Search", label: "Open Search" },
    { key: "Open_Web", label: "Open Web" }
]
const Save = (props: any) => {
    let errorInfo = {
        title: {
            message: "",
            type: ""
        },
        body: {
            message: "",
            type: ""
        },
        scheduledTime: {
            message: "",
            type: ""
        }
    };

    const dispatch = useDispatch();
    // const [isDeleteImage, setIsDeleteImage] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [imgSearch, setImgSearch] = useState("");
    const [preIcons, setPreIcons] = useState([]);
    const [selectedPreIcon, setSelectedPreIcon] = useState(null);
    // const [popupConfirm, setPopupConfirm] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedAudience, setSelectedAudience] = useState(Audience[0].key);
    const [scheduledTime, setScheduledTime] = useState(null);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [body, setBody] = useState(null);
    const [status, setStatus] = useState(null);
    // const [searchProduct, setSearchProduct] = useState(null);
    const [additionalData, setAdditionalData] = useState(CallToActions[0].key);
    const [additionalText, setAdditionalText] = useState(null);
    const [error, setError] = useState(errorInfo);
    const [visibleUploadFile, setVisibleUploadFile] = useState(false);
    const [tempImage, setTempImage] = useState(null);
    const renderImagePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        // setPreviewVisible(true);
        // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    useEffect(() => {
        if (props.selectedNotification) {
            setTitle(props.selectedNotification.title);
            setBody(props.selectedNotification.body);
            // setAdditionalData(props.selectedNotification.additionalData);
            if (props.selectedNotification.additionalData) {
                if (props.selectedNotification.additionalData.deepLink.indexOf("/c/") > -1) {
                    setAdditionalData("Open_Category");
                    setSelectedCategory(props.selectedNotification.additionalData.deepLink.replace("/c", ""));
                } else if (props.selectedNotification.additionalData.deepLink.indexOf("/p/") > -1) {
                    setAdditionalData("Open_Product_Detail");
                    setSelectedProduct(props.selectedNotification.additionalData.deepLink.replace("/p", ""));
                } else if (props.selectedNotification.additionalData.deepLink.indexOf("/s/") > -1) {
                    setAdditionalData("Open_Search");
                    setAdditionalText(props.selectedNotification.additionalData.deepLink.replace("/s/", ""));
                } else if (props.selectedNotification.additionalData.deepLink.indexOf("https://") > -1) {
                    setAdditionalData("Open_Web");
                    setAdditionalText(props.selectedNotification.additionalData.deepLink);
                }
            }
            if (props.selectedNotification.imageUrl) {
                setFileList([{
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: props.selectedNotification.imageUrl,
                }]);
            }
            if (props.selectedNotification.scheduledTime) {
                setScheduledTime(dayjs(props.selectedNotification.scheduledTime));
                setStatus("scheduled");
            } else {
                setStatus("now");
            }
        } else {
            setIsEdit(true);
        }
    }, [props.selectedNotification]);
    useEffect(() => {
        loadDynamicData();
        //eslint-disable-next-line
    }, [additionalData]);
    const loadDynamicData = async () => {
        if (additionalData === "Open_Category") {
            let resp = await getCategories()(dispatch);
            if (resp && resp.categories) {
                setCategories(resp.categories);
            }
        } else if (additionalData === "Open_Product_Detail") {
            let resp = await getProducts()(dispatch);
            if (resp) {
                setProducts(resp);
            }
        }

    }
    const onHandleFileChange = (info) => {
        setSelectedPreIcon(null);
        info.file.status = "isNew";
        setTempImage([info.file]);
        renderImagePreview(info.file);
    }
    const uploadProps = {
        accept: "image/jpeg,image/png,image/webp",
        name: 'file',
        multiple: false,
        action: '',
        onChange: onHandleFileChange
    };

    const showModal = async () => {
        if (isEdit) {
            const resp = await getMediaFiles()(dispatch);
            if (resp && resp.media) {
                setPreIcons(resp.media);
            }
            setVisibleUploadFile(true);
        }
    };

    const handleOk = e => {
        setVisibleUploadFile(false);
        setFileList(tempImage);
        setPreviewImage(null)
        if (selectedPreIcon) {
            setFileList([{
                url: config.baseImageUrl + selectedPreIcon
            }]);
        }
    };

    const handleCancel = e => {
        setPreviewImage(null);
        setTempImage(null);
        setVisibleUploadFile(false);
    };
    const onUploadImage = async () => {
        let resp = null;
        let bodyFormData = new FormData();
        bodyFormData.append('images', fileList[0].originFileObj);
        bodyFormData.append('forUse', 'notificationImage');
        resp = await onUploadMedia(bodyFormData)(dispatch);
        if (resp.data && resp.data.isUploaded) {
            console.log(resp.data);
        }

    }
    const removeImage = async () => {
        if (fileList && fileList.length > 0) {
            setFileList([]);
            // setIsDeleteImage(true);
        }
    }
    const onClose = () => {
        setIsEdit(false);
        setError({
            title: {
                message: "",
                type: ""
            },
            body: {
                message: "",
                type: ""
            },
            scheduledTime: {
                message: "",
                type: ""
            }
        });
        props.onClose();
    }
    const onSubmit = async () => {
        const payload = {
            title: title,
            body: body,
            scheduledTime: scheduledTime,
            audience: selectedAudience
        };
        let isError = false;
        if (!isNotNullOrUndefinedString(title)) {
            errorInfo.title.message = "Please enter title";
            errorInfo.title.type = "error";
            isError = true;
        } else {
            errorInfo.title.message = "";
            errorInfo.title.type = "";
        }
        if (!isNotNullOrUndefinedString(body)) {
            errorInfo.body.message = "Please enter body";
            errorInfo.body.type = "error";
            isError = true;
        } else {
            errorInfo.body.message = "";
            errorInfo.body.type = "";
        }
        if (status === "now") {
            delete payload.scheduledTime;
        } else {
            if (!scheduledTime) {
                errorInfo.scheduledTime.message = "Please select schedule date";
                errorInfo.scheduledTime.type = "error";
                isError = true;
            } else {
                errorInfo.scheduledTime.message = "";
                errorInfo.scheduledTime.type = "";

            }
        }
        if (additionalData === "Open_Category") {
            payload['additionalData'] = {
                deepLink: `/c${selectedCategory}`
            }
        } else if (additionalData === "Open_Product_Detail") {
            payload['additionalData'] = {
                deepLink: `/p/${selectedProduct}`
            }
        } else if (additionalData === "Open_Search") {
            payload['additionalData'] = {
                deepLink: `/s/${additionalText}`
            }
        } else if (additionalData === "Open_Web") {
            payload['additionalData'] = {
                deepLink: `${additionalText}`
            }
        }
        setError(errorInfo);
        if (!isError) {
            setIsLoading(true);
            if (fileList && fileList.length > 0) {
                if (fileList[0].status === "isNew") {
                    const resp = await onUploadImage();
                    console.log({ resp });
                } else if (fileList[0].url) {
                    payload['imageUrl'] = fileList[0].url;
                }
            }
            if (props.selectedNotification?.id) {
                const resp = await onUpdateNotification(props.selectedNotification.id, payload)(dispatch);
                if (resp.success) {
                    onClose();
                }
            } else {
                const resp = await onCreateNotification(payload)(dispatch);
                if (resp) {
                    onClose();
                }
            }
            setIsLoading(false);
        }
    }
    return <Drawer
        width={"100%"}
        closable={false}
        onClose={() => { setError(errorInfo); props.onClose(); }}
        className="notification-save"
        visible={props.isVisible}
        title={
            <div className="header">
                <ArrowLeftOutlined className="arrow" onClick={() => { setIsEdit(false); setError(errorInfo); props.onClose(); }}></ArrowLeftOutlined>
                <div className="title">{props.selectedNotification ? "Update " : "Create "}Notification Details</div>
            </div>
        }
        footer={
            <div className='footer'>
                <div className="sp_btn" onClick={() => { setIsEdit(false); setError(errorInfo); props.onClose(); }} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                {isEdit && status && <div className="sp_btn" onClick={onSubmit} style={{ width: 164, fontSize: 14 }}>
                    {status === "scheduled" ? "Schedule Now" : "Send Now"}
                </div>}
            </div>
        }>
        <div className="content" >
            <div className='group'>
                <div className="section">
                    <label >Title *</label>
                    <div>
                        <InputValidation type={error.title.type} message={error.title.message}>
                            <input className="value" onChange={(e) => setTitle(e.target.value)} disabled={!isEdit} value={title} />
                        </InputValidation>
                    </div>
                    <div className='divider'></div>
                    <label >Body *</label>
                    <div>
                        <InputValidation type={error.body.type} message={error.body.message}>
                            <textarea className="value" onChange={(e) => setBody(e.target.value)} disabled={!isEdit} value={body} />
                        </InputValidation>
                    </div>
                </div>
                <div className='section'>
                    {isEdit && fileList && fileList.length === 0 && <div className="group">
                        <label><span style={{ fontWeight: 600, color: "#000", marginRight: 4 }}>Media</span>(Accepts png, jpeg, jpg, webp)</label>
                        <div onClick={showModal} className="icon">
                            <img alt='Plus Icon' src={PlusSvg} />
                            <div className='text'>Add Image</div>
                        </div>
                    </div>}
                    {fileList && fileList.length > 0 && <div className="group">
                        <label><span style={{ fontWeight: 600, color: "#000", marginRight: 4 }}>Media</span>(Accepts png, jpeg, jpg, webp)</label>
                        <div className="icon" >
                            {isEdit && <div className="action">
                                <DeleteOutlined onClick={removeImage} style={{ cursor: "pointer", color: "#FFF", fontSize: 18 }} />
                                <EditOutlined onClick={showModal} style={{ cursor: "pointer", color: "#FFF", fontSize: 18 }} />
                            </div>}
                            <img style={{
                                maxWidth: '134px',
                                maxHeight: '134px',
                                padding: 2
                            }} alt='Preview' onClick={showModal} src={
                                fileList[0].url ? fileList[0].url.indexOf("http") > -1 ? fileList[0].url : `${config.baseImageUrl}${fileList[0].url}` : fileList[0].preview
                            } />
                        </div>
                    </div>}
                </div>
                <div className="section">
                    <label style={{ fontWeight: 600, color: "#000", fontSize: 15 }}>Call To Action *</label>
                    <div style={{ marginTop: 15, display: "flex" }}>
                        <Select style={{ width: "50%", marginRight: 10 }} defaultValue={additionalData} className="value" onChange={(val) => { console.log(val); setAdditionalData(val) }} disabled={!isEdit} >
                            {CallToActions.map((item) => {
                                return <Option key={item.key} value={item.key}>{item.label}</Option>
                            })}
                        </Select>
                        <div style={{ width: "50%", marginLeft: 10 }}>
                            {additionalData === "Open_Category" && <div className="category">
                                <Select style={{ height: 40 }}
                                    // disabled={!isEdit || selectedCategory ? true : false}
                                    defaultValue={selectedCategory}
                                    disabled={!isEdit}
                                    onChange={(value) => setSelectedCategory(value)}
                                    showSearch={true}
                                    optionFilterProp="children"
                                >
                                    {
                                        categories && categories.map((item, i) => {
                                            return <Option key={item.category + "_" + i} value={item.category}>{item.title}</Option>
                                        })
                                    }
                                </Select>
                            </div>}
                            {additionalData === "Open_Product_Detail" && <div className="category">
                                <Select
                                    showSearch={true}
                                    suffixIcon={
                                        selectedProduct ? (
                                            <CloseCircleOutlined onClick={() => { setSelectedProduct(null); }} />
                                        ) : (
                                            <CaretDownOutlined />
                                        )
                                    }
                                    disabled={!isEdit}
                                    className="add-product"
                                    placeholder="Select to add product"
                                    onChange={(slug: string) => {
                                        setSelectedProduct(slug);
                                    }}
                                >
                                    {products.map((p) => {
                                        return (
                                            <Option key={p.slug} value={p.slug}>
                                                <div
                                                    style={{
                                                        height: 44,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#121212",
                                                            fontWeight: 900,
                                                            textTransform: "uppercase",
                                                            height: 14
                                                        }}
                                                    >
                                                        {getL10N(p, "title", null).i10n}
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "#ADB7C3",
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {getL10N(p, "subtitle", null).i10n}
                                                    </div>
                                                </div>
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>}
                            {(additionalData === "Open_Search" || additionalData === "Open_Web") && <input type='text' onChange={(e) => {
                                setAdditionalText(e.target.value);
                            }} />}
                        </div>
                    </div>
                </div>
                <div className="section">
                    <label style={{ fontWeight: 600, color: "#000", fontSize: 15 }}> How would like to create the notification? *</label>
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                        marginTop: 15
                    }}>
                        <div onClick={() => {
                            setStatus("now");
                        }} style={{
                            width: 365,
                            height: 84,
                            borderRadius: 6,
                            border: "1px solid #BAC3D0",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                            justifyContent: "center",
                            marginRight: 10,
                            background: status === "now" ? "#121212" : "#FFF",
                        }}>
                            <img src={DeliveryNotifySvg} alt='Delivery Notification' />
                            <label style={{ fontWeight: 500, color: status === "now" ? "#FFF" : "#292D32", fontSize: 13 }}>Delivery Notification</label>
                        </div>
                        <div onClick={() => {
                            setStatus("scheduled");
                        }} style={{
                            width: 365,
                            height: 84,
                            borderRadius: 6,
                            border: "1px solid #BAC3D0",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                            background: status === "scheduled" ? "#121212" : "#FFF",
                            marginLeft: 10
                        }}>
                            <img src={ScheduleNotifySvg} alt='Schedule Notification' />
                            <label style={{ fontWeight: 500, fontSize: 13, color: status === "scheduled" ? "#FFF" : "#292D32", }}>Schedule Notification</label>
                        </div>
                    </div>
                </div>
                {status === "scheduled" && <div className="section">
                    <label style={{ fontWeight: 600, color: "#000", fontSize: 15 }}>Schedule Date *</label>
                    <div className='divider'></div>
                    <InputValidation style={{ width: 365 }} type={error.scheduledTime.type} message={error.scheduledTime.message}>
                        <DatePicker format="DD-MM-YYYY hh:mm A" showTime={{ format: 'hh:mm A', use12Hours: true }} style={{ width: 365, height: 40, borderBottomWidth: 0 }} placeholder='Select Date want deliver notification' defaultValue={scheduledTime} onChange={(date, dateString) => {
                            setScheduledTime(date);
                        }} />
                    </InputValidation>
                </div>}
            </div>
            <div className='group'>
                <div className="section" style={{ width: 395 }}>
                    <label style={{ fontWeight: 600, color: "#000", fontSize: 15 }}> Audience *</label>
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                        marginTop: 15,
                        flexDirection: "column"
                    }}>
                        {Audience.map((item) => {
                            return <div key={item.key} onClick={() => {
                                setSelectedAudience(item.key);
                            }} style={{
                                width: 365,
                                borderRadius: 6,
                                height: 40,
                                padding: "0px 10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px solid #D9D9D9",
                            }}>
                                <label style={{ fontWeight: 500, color: "#292D32", fontSize: 14 }}>{item.label}</label>
                                {selectedAudience === item.key && <img src={SelectedCricleSvg} alt='Selected' />}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
        <Modal
            width={(window.innerWidth / 2) - 200}
            className="image-upload"
            title="Set Icons"
            visible={visibleUploadFile}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <button key="back" className="sp_btn cancel" onClick={handleCancel}>
                    Cancel
                </button>,
                <button key="submit" className="sp_btn upload" onClick={handleOk}>
                    Upload
                </button>
            ]}
        >
            <Tabs>
                <TabPane className="pre-icons" tab="Pre-Icons" key="1">
                    <Input className="search" type="text" suffix={<img alt='Search Icon' src={SearchIcn} />} onChange={(e) => setImgSearch(e.target.value)} />
                    <div className="pics" style={{ height: window.innerHeight / 2 }}>
                        {preIcons.map((item) => {
                            return ((item.tags?.find((el => el.indexOf(imgSearch.toLowerCase()) > -1)) || imgSearch.length === 0) &&
                                <div key={item.id} style={{ borderColor: (selectedPreIcon && selectedPreIcon === item.objectUrl) ? 'red' : '#00000000' }}> <img alt='Pre Icon' onClick={() => setSelectedPreIcon(item.objectUrl)} src={config.baseImageUrl + item.objectUrl} /></div>
                            )
                        })}
                    </div>
                </TabPane>
                <TabPane tab="Upload Image" key="2" className="from-local">
                    <Dragger showUploadList={false} customRequest={() => { return false; }} defaultFileList={fileList} {...uploadProps} >
                        <p className="ant-upload-drag-icon">
                            {!previewImage && <img alt='Upload Icon' src={UploadIcn} />}
                            {previewImage && <img alt='Preview' className="preview-image" src={previewImage} />}
                        </p>
                        <p className="ant-upload-text">Drag a icon here</p>
                        <p className="ant-upload-text">---- or ----</p>
                        <p className="ant-upload-hint">
                            <span className="select-icn">Select Icon</span>
                        </p>
                    </Dragger>
                </TabPane>
            </Tabs>
        </Modal>
        {isLoading && <Loading background="#00000070"></Loading>}
    </Drawer>
}

export default Save;