import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'antd';
import { useDispatch } from 'react-redux';
import SearchIcn from "../../../assets/images/search.svg";
import EditSvg from "../../../assets/images/settings/edit.svg";
import { createBlog, getBlogs, updateBlog } from "../../../actions/blogActions";
import InputValidation from '../common/InputValidation';
import dayjs from 'dayjs';
import Loading from '../common/Loading';


const errorInfo = {
    title: {
        message: "",
        type: "",
    }
};

const Blog = () => {
    const windowHeight = window.innerHeight;
    const dispatch: any = useDispatch();
    const [error, setError] = useState(errorInfo);
    const [search, setSearch] = useState("");
    const [blogList, setBlogList] = useState(null);
    const [blogs, setBlogs] = useState(null);
    const [edit, setEdit] = useState(null);
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isNew, setIsNew] = useState(false);
    useEffect(() => {
        setTitle(null);
        setEdit(null);
        getBlogList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getBlogList = async () => {
        setIsLoading(true);
        const resp = await getBlogs()(dispatch);
        if (resp?.blogs) {
            setBlogs(resp.blogs);
        } else {
            setBlogs([]);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        if (search) {
            const filteredList = blogs?.filter((item) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
            setBlogList(filteredList);
        } else {
            setBlogList(blogs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    useEffect(() => {
        if (blogs) {
            setBlogList(blogs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogs]);
    const onSave = async () => {
        setIsLoading(true);
        if (title) {
            let resp = null;
            if (edit?.id) {
                resp = await updateBlog(edit.id, { title })(dispatch);
            } else {
                resp = await createBlog({ title })(dispatch);
            }
            if (resp?.blogId || resp?.isUpdated) {
                setIsNew(false);
                getBlogList();
            }
        } else {
            setError({
                ...error,
                title: {
                    message: "Please enter blog title",
                    type: "error"
                }
            });
        }
        setIsLoading(false);
    }
    useEffect(() => {
        if (edit) {
            setIsNew(true);
            setTitle(edit.title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit]);
    // const onDelete = async (item: any) => {
    // }
    return <Row className="blog">
        <Col span={24} style={{ height: windowHeight - 80 }}>
            {isNew && <div className="new-blog">
                <div className="container">
                    <div className='box'>
                        <div className="title">Create New Blog</div>
                        <div className="content">
                            <InputValidation type={error.title.type} message={error.title.message}>
                                <Input className="title-input" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </InputValidation>
                        </div>
                        <div className='action'>
                            <button className="sp_btn btn cancel" onClick={() => setIsNew(false)} >Cancel</button>
                            <button className="sp_btn btn create" onClick={onSave} >{edit?.id ? "Update" : "Create"}</button>
                        </div>
                    </div>
                </div>
            </div>}
            {blogs?.length > 0 && <div className="action-bar">
                <div className="title">
                    You can add or edit new blog
                </div>
                <div className="actions">
                    <Input className="search" value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                    <button className="sp_btn btn" onClick={() => { setTitle(null); setEdit(null); setIsNew(true); }} >+ Add New Blog</button>
                </div>
            </div>}
            <div className="content">
                {blogs?.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column', width: '100%' }}>
                    <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Blog</div>
                    <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first BLOG.</div>
                    <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Blogs yet...</div>
                    <div style={{ display: 'flex' }}>
                        <button onClick={() => setIsNew(true)} className="sp_btn" style={{ width: 136 }} >+ Add New Blog</button>
                    </div>
                </div>}
                {blogList?.length > 0 && <div className="list">
                    {blogList.map((item: any, index: number) => {
                        return <div className="item" key={index} onClick={() => setEdit(item)}>
                            <div className="title">{item.title}</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="date">{dayjs(item.createdAt).calendar(null, {
                                    sameDay: "[Today] h:mm A",
                                    nextDay: "[Tomorrow] h:mm A",
                                    nextWeek: "ddd h:mm A",
                                    lastDay: "[Yesterday] h:mm A",
                                    lastWeek: "[Last] dddd h:mm A",
                                    sameElse: "MMM D YYYY [at] h:mm A",
                                })}</div>
                                <div className="action">
                                    <img src={EditSvg} alt='Edit Icon' style={{ marginRight: 17 }} onClick={() => setEdit(item)} />
                                    {/* <img src={DeleteSvg} alt='Delete Icon' onClick={() => onDelete(item)} /> */}
                                </div>
                            </div>
                        </div>
                    })}
                </div>}
                {blogs?.length > 0 && blogList?.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column', width: '100%' }}>
                    <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Search not found any records</div>
                </div>}
            </div>
        </Col>
        {isLoading && <Loading background="#00000070"></Loading>}
    </Row>
}

export default Blog;