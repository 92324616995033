import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Input } from 'antd';
import '../../../assets/sass/dsk/navigation.sass';
import SearchIcn from "../../../assets/images/search.svg"
import deleteIcn from '../../../assets/images/settings/trash.svg';
import editIcn from '../../../assets/images/settings/edit.svg';
import PopupConfirm from '../common/PopupConfirm';
import Loading from '../common/Loading';
import { getNavMenus, deleteMenu } from '../../../actions/navigationAction';
import { Save } from './Save';
import SaveMenuItem from './SaveMenu';

const ListedNavigation = (props: any) => {
    const WindowHeight = window.innerHeight;
    const dispatch = useDispatch();
    const [filterCollections, setFilterCollections] = useState([]);
    const [menus, setMenus] = useState([]);
    const [titleSearch, setTitleSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isAddMenu, setIsAddMenu] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [menuId, setMenuId] = useState(null);

    useEffect(() => {
        loadNavigationMenus();
        //eslint-disable-next-line
    }, [selectedStore]);

    useEffect(() => {
        loadNavigationMenus();
        //eslint-disable-next-line
    }, []);

    const onAction = (mId, action) => {
        if (action === "new") {
            setIsAddMenu(true);
        }
        if (action === "edit") {
            setMenuId(mId)
        }
        // setIsSave(true);
    }

    const loadNavigationMenus = async () => {
        let resp: any = await getNavMenus()(dispatch);
        if (resp && resp.menus) {
            setMenus(resp.menus);
        }
    }

    useEffect(() => {
        onFilterCollections();
        //eslint-disable-next-line
    }, [titleSearch, menus])

    const onFilterCollections = () => {
        let filterList = [];
        let isTitle = false;
        // let isType = false;
        for (let c of menus) {
            isTitle = false;
            // isType = false;
            if (titleSearch) {
                if (c.title && c.title.toLowerCase().indexOf(titleSearch.toLowerCase()) > -1) {
                    isTitle = true
                }
                if (c.description && c.description.toLowerCase().indexOf(titleSearch.toLowerCase()) > -1) {
                    isTitle = true
                }
            } else {
                isTitle = true;
            }
            // if (typeSearch && typeSearch !== "all") {
            //     if (c.type && c.type.toLowerCase().indexOf(typeSearch.toLowerCase()) > -1) {
            //         isType = true
            //     }
            // } else {
            //     isType = true
            // }
            if (isTitle) {
                filterList.push(c);
            }
        }
        setFilterCollections(filterList)
    }

    const onDelete = (item) => {
        const title = `Remove Menu`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{item.title}</span> menu from <span style={{ fontWeight: "bold" }}>navigations</span></span></div > },
            yes: {
                label: "Remove",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await deleteMenu(item.id)(dispatch);
                    if (resp && resp.isDeleted) {
                        loadNavigationMenus();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    return (<>
        {menus && menus.length ? <Row className="collection">
            <Col className="list" style={{ height: WindowHeight - 120 }}>
                <div className="action-bar">
                    <div className="title">
                        You've {menus && menus.length} Navigations
                    </div>
                    <div className="actions">
                        <button className="sp_btn" onClick={(e) => { e.stopPropagation(); onAction(null, "new") }} >+ Add Menu</button>
                        {/* <button className="sp_btn">Export</button> */}
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={16}>
                            Title
                        </Col>
                        <Col span={4}>
                            Menu Items
                        </Col>
                        <Col style={{ textAlign: "center" }} span={4}>
                            Actions
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={16}>
                            <Input style={{ width: 234 }} className="filter" name="titleSearch" onChange={(e) => setTitleSearch(e.target.value)} placeholder="Title" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={4}>
                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: WindowHeight - 284 }}>
                        <Col span={24}>
                            {filterCollections && filterCollections.map((record, index) =>
                                <Row className="item" onClick={(e) => { e.stopPropagation(); onAction(record.id, "edit"); }} key={record.id} style={{ border: menus.length - 1 <= index ? "borderTop: 1px solid #f1f1f1" : "" }}>
                                    <Col span={16}>
                                        <div>{record.title}</div>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ textTransform: 'capitalize' }}>{record.type}</div>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ fontSize: "14px", display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ width: 100, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                <img alt='Edit Icon' src={editIcn} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); onAction(record.id, "edit") }} />
                                                <img alt='Delete Icon' src={deleteIcn} style={{ cursor: "pointer", height: 20 }} onClick={(e) => { e.stopPropagation(); onDelete(record) }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row > : <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: WindowHeight - 200, flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Navigation Menu</div>
            <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first Collection.</div>
            <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no navigation menu yet...</div>
            <div style={{ display: 'flex' }}>
                <button className="sp_btn" onClick={(e) => { e.stopPropagation(); onAction(null, "new"); }} >+ Add Menu</button>
            </div>
        </div>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
        <SaveMenuItem onClose={(data) => {
            setIsAddMenu(false);
            if (data && data.id) {
                loadNavigationMenus();
            }
        }} isVisible={isAddMenu} />
        <Save onClose={() => { setMenuId(null); }} menuId={menuId} isVisible={menuId ? true : false} />
    </>)
}

export default ListedNavigation;
