import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import { uuidv4 } from '../helpers/util';
import axiosWrapper from "./axiosWrapper";

let _sessionToken = null;
export function sendOTP(countryCode, mobileNo) {
    const options = {
        method: 'PUT',
        data: {
            "countryCode": countryCode,
            mobileNo
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    let url = `${config.baseUrl}${config.sendOTPUrl}`;
    return async (dispatch) => {
        try {
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            dispatch({
                type: type.SEND_OTP_SUCCESS,
                data: new Date().getTime()
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Send OTP Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Send OTP', error);
            }
            // axios(url, options)
            //     .then(function (resp) {
            //         dispatch({
            //             type: type.SEND_OTP_SUCCESS,
            //             data: new Date().getTime()
            //         });
            //     })
            //     .catch(function (error) {
            //         if (error.response && error.response.data && error.response.data.message) {
            //             dispatch({
            //                 type: type.ERROR,
            //                 data: {
            //                     title: "Send OTP Failed",
            //                     message: error.response.data.message
            //                 }
            //             });
            //         } else {
            //             console.error('error', 'Send OTP', error);
            //         }
            //     });
        }
    }
}

export function verifyOTP(countryCode, mobileNo, otp) {
    const options = {
        method: 'PUT',
        data: {
            countryCode,
            mobileNo,
            otp
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.verifyOTPUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            dispatch({
                type: type.VERIFY_OTP_SUCCESS,
                data: true
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                // dispatch({
                //     type: type.ERROR,
                //     data: {
                //         title: "Verify OTP Failed",
                //         message: error.response.data.message
                //     }
                // });
                return { error: error.response.data.message };
            } else {
                console.error('error', 'Verify OTP', error);
            }
        }
    }
}

export const getStoreAvailable = (storeName) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.storeAvailabilityUrl.replace('{storeName}', storeName)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code.includes('SC') > -1) {
                    return { error: error.response.data.message }
                } else {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Store Available Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
            } else {
                console.error('error', 'Store Available', error);
            }
        }
    };
};

export const getDomainAvailable = (domain) => {
    let response;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        let url = `${config.baseUrl}${config.domainLookupUrl.replace('{domain}', domain)}`
        axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } })
            .then(function (resp) {
                response = resp;
                dispatch({
                    type: type.GET_DOMAIN_AVAILABLE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Get Domain Available Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                } else {
                    console.error('error', 'Get Domain Available', error);
                }
            });
    }
};


export const searchAddress = (data) => {
    const options = {
        method: 'GET'
    };
    return async (dispatch) => {
        try {
            if (!_sessionToken) {
                _sessionToken = uuidv4();
            }
            let url = `${config.baseUrl}${config.searchAddressUrl.replace('{data}', data)}&sessionToken=${_sessionToken}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? Object.assign(resp.data, { _sessionToken }) : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Search Address Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Search Address', error);
            }
        }
    };
};

export const getStores = (currentStore = null, tokenInfo = null) => {
    const { selectedStore } = store.getState().ui;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        method: 'GET'
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.storesUrl}`;
            const resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            let stores = null
            if (resp && resp.data && resp.data.stores) {
                stores = resp.data.stores;
            }
            if (!selectedStore && stores) {
                const selectStore = stores.find((s) => s.name === currentStore);
                if (selectStore) {
                    dispatch({
                        type: type.SELECTED_STORE,
                        data: selectStore
                    });
                } else {
                    dispatch({
                        type: type.SELECTED_STORE,
                        data: stores[0]
                    });
                }
                dispatch({
                    type: type.STORES,
                    data: stores
                });
            }
            return stores;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Stores Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Get Stores', error);
            }
        }
    };
};

export const getAddress = (placeId, sessionToken) => {
    const options = {
        method: 'GET'
    };
    return async (dispatch) => {
        try {
            const url = `${config.baseUrl}${config.getAddressInfoUrl.replace('{placeId}', placeId)}&sessionToken=${sessionToken}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Address",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Get Address', error);
            }
        }
    };
};

// export const searchAddress = (data) => {
//     const options = {
//         method: 'GET'
//     };
//     return async (dispatch) => {
//         let sessionToken = uuid();
//         let url = `${config.baseUrl}${config.searchAddressUrl.replace('{data}', data)}&sessionToken=${sessionToken}`
//         axios(url, options)
//             .then(function (resp) {
//                 dispatch({
//                     type: type.SEARCH_ADDRESS_SUCCESS,
//                     data: Object.assign(resp.data, { sessionToken })
//                 });
//             })
//             .catch(function (error) {
//                 if (error.response && error.response.data && error.response.data.message) {
//                     dispatch({
//                         type: type.ERROR,
//                         data: {
//                             title: "Search Address Failed",
//                             message: error.response.data.message
//                         }
//                     });
//                 } else {
//                     console.error('error', 'Authentication', error);
//                 }
//             });
//     }
// };

// export const getAddress = (placeId, sessionToken) => {
//     const options = {
//         method: 'GET'
//     };
//     return async (dispatch) => {
//         let url = `${config.baseUrl}${config.getAddressInfoUrl.replace('{placeId}', placeId)}&sessionToken=${sessionToken}`
//         axios(url, options)
//             .then(function (resp) {
//                 dispatch({
//                     type: type.GET_ADDRESS_SUCCESS,
//                     data: resp.data
//                 });
//             })
//             .catch(function (error) {
//                 if (error.response && error.response.data && error.response.data.message) {
//                     dispatch({
//                         type: type.ERROR,
//                         data: {
//                             title: "Get Address Failed",
//                             message: error.response.data.message
//                         }
//                     });
//                 } else {
//                     console.error('error', 'Authentication', error);
//                 }
//             });
//     }
// };

export const createNewStore = (data) => {
    const { token } = store.getState().auth.info;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        method: 'POST',
        data
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.createNewStoreUrl}`;
            const resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            let store = null
            if (resp && resp.data && resp.data.newStore) {
                store = resp.data.newStore;
            }
            dispatch({
                type: type.SELECTED_STORE,
                data: store
            });
            dispatch({
                type: type.SELECTED_STORE_DETAILS,
                data: store
            });
            dispatch({
                type: type.STORES,
                data: store ? [store] : null
            });
            return store;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code.includes('SC') > -1) {
                    return { error: error.response.data.message }
                } else {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Create Store",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
            } else {
                console.error('error', 'Get Create Store', error);
            }
        }
    };

}

export const getSubscriptionPlans = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getSubscriptionPlansUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Subscription Plans",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Get Address', error);
            }
        }
    }
}

export const onCancelSubscription = (subscriptionId) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        try {
            const sId = store.getState().ui.selectedStore.id;
            let url = `${config.baseUrl}${config.cancelSubscriptionUrl.replace('{sId}', sId).replace('{subscriptionId}', subscriptionId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Cancel Subscription Failure",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Cancel Subscription Failure', error);
            }
        }
    }
}

export const onlinePaymentSuccess = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.paymentSuccessUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Online Payment Success",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Online Payment ', error);
            }
        }
    }
}

export const onlinePaymentFailure = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.paymentFailureUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Online Payment Failure",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Online Payment Failure', error);
            }
        }
    }
}

export const createSubscription = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.createSubscriptionUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Subscription Plan",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Create Subscription Plan', error);
            }
        }
    }
}

export const updateSubscription = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.createSubscriptionUrl}?sid=${sId}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Subscription Plan",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Create Subscription Plan', error);
            }
        }
    }
}

export const getIndustryTypes = () => {
    let response;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        let url = `${config.baseUrl}${config.getIndustryTypesUrl}`
        axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } })
            .then(function (resp) {
                response = resp;
                dispatch({
                    type: type.GET_INDUSTRY_TYPES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Get Industry Types Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                } else {
                    console.error('error', 'Authentication', error);
                }
            });
    }
};

export const createMerchant = (data) => {
    let { info } = store.getState().auth;
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.createMerchantUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            if (resp && resp.data.isUpdated) {
                info.isMobileVerified = true;
                dispatch({
                    type: type.AUTH_SUCCESS,
                    data: info
                });
            }
            return resp && resp.data && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Merchant",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Create Merchant', error);
            }
        }
    }
}

export function purchaseService(data, subscriptionId) {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    return async (dispatch) => {
        try {
            const sId = store.getState().ui.selectedStore.id;
            let url = `${config.baseUrl}${config.purchaseServiceUrl.replace('{sId}', sId).replace('{subscriptionId}', subscriptionId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                return { error: error.response.data.message };
            } else {
                console.error('error', 'Purchase Service', error);
            }
        }
    }
}